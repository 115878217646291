import React, { Component } from "react";
import { injectIntl } from "gatsby-plugin-react-intl";

import "./styling/faq.css";
import { Question } from "../components/question/question";
import Layout from "../components/layout/layout";

class Faq extends Component {
  render() {
    const path = this.props.location.pathname;

    return (
      <Layout
        display="true"
        path={path}
        title={this.props.intl.formatMessage({ id: "faq.title" })}
      >
        <div className="faqContainer">
          <Question question="faq.Question1" answer="faq.Answer1" />
          <Question question="faq.Question2" answer="faq.Answer2" />
          <Question question="faq.Question3" answer="faq.Answer3" />
          <Question question="faq.Question4" answer="faq.Answer4" />
          <Question question="faq.Question5" answer="faq.Answer5" />
          <Question question="faq.Question6" answer="faq.Answer6" />
          <Question question="faq.Question7" answer="faq.Answer7" />
          <div className="fillContainer" />
        </div>
      </Layout>
    );
  }
}

export default injectIntl(Faq);
