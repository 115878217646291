import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "./question.css";
import { FormattedMessage } from "gatsby-plugin-react-intl";

export const Question = (props) => {
  const [displayAnswer, setDisplayAnswer] = useState(false);
  const [color, setColor] = useState("");
  const [rotation, setRotation] = useState(0);

  // Rotates the Plus
  const rotate = () => {
    let newRotation = rotation + 45;
    if (newRotation >= 360) {
      newRotation = newRotation - 360;
    }

    setRotation(newRotation);
  };

  // Opens the Answer
  const openAnswer = () => {
    rotate();
    let newColor;
    if (color === "") {
      newColor = "#f9f9f9";
    } else {
      newColor = "";
    }

    setDisplayAnswer(!displayAnswer);
    setColor(newColor);
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          plusIcon: file(relativePath: { eq: "icons/plus.png" }) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={({ plusIcon }) => (
        <div style={{ backgroundColor: color }} className="QuestionContainer">
          <div
            onClick={() => {
              openAnswer();
            }}
            className="question"
          >
            <p>
              <FormattedMessage id={props.question} />
            </p>
            <div className="plus">
              <Img
                className="plus"
                style={{ transform: `rotate(${rotation}deg)` }}
                fluid={plusIcon.childImageSharp.fluid}
              />
            </div>
          </div>
          {displayAnswer && (
            <div className="answer">
              <p>
                <FormattedMessage id={props.answer} />
              </p>
            </div>
          )}
        </div>
      )}
    />
  );
};
